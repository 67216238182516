import React from 'react'
import PropTypes from 'prop-types'
import CheckBoxStyled from './styled'

class CheckBox extends React.PureComponent {
  onCheckBoxCheck = e => {
    this.props.onCheck(e.target.checked, this.props.value)
  }

  onLabelClicked = () => {
    this.props.onCheck(!this.inputCheckbox.checked, this.props.value)
  }

  renderLabel = () => {
    return (
      <label htmlFor="styled-checkbox" className="prevent-text-overflow" onClick={this.onLabelClicked}>
        {this.props.label}
      </label>
    )
  }

  renderCheckBox() {
    let className = 'check-box'
    className += this.props.checked ? ' checked' : ''
    return (
      <>
        <span className={className} />
        <input
          className="styled-checkbox"
          checked={this.props.checked}
          type="checkbox"
          value={this.props.value}
          ref={ref => {
            this.inputCheckbox = ref
          }}
          onChange={this.onCheckBoxCheck}
        />
        {this.renderLabel()}
      </>
    )
  }

  render() {
    let { className } = this.props
    className += this.props.disabled ? ' disabled' : ''
    return <CheckBoxStyled className={className}>{this.renderCheckBox()}</CheckBoxStyled>
  }
}

CheckBox.defaultProps = {
  className: '',
  label: '',
  checked: false,
  disabled: false,
}

CheckBox.propTypes = {
  className: PropTypes.string,
  checked: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.any,
  onCheck: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

export default CheckBox
