import styled from 'styled-components'

export default styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  &.disabled {
    pointer-events: none;
    .check-box {
      border: 1px solid #4a4a4a;
      &.checked {
        background-color: #4a4a4a;
      }
    }
    label {
      color: #4a4a4a;
    }
  }
  .check-box {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    border: 1px solid #981214;
    &.checked {
      background-color: #981214;
    }
    &:hover {
      cursor: pointer;
    }
  }
  .styled-checkbox {
    position: absolute;
    opacity: 0;
    width: 12px;
    height: 12px;
    top: 0;
    &:hover {
      cursor: pointer;
    }
  }
  label {
    font-size: 12px;
    &:hover {
      cursor: pointer;
    }
  }
  span {
    min-width: 8px;
    min-height: 8px;
  }
`
